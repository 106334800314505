import React,{useState, useEffect} from 'react'
import SideBarProjects from '../../Components/SideBar/SideBarProjects'
import {projectsCollectionRef, clientsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query,  doc, where, updateDoc} from "firebase/firestore"
import { db } from '../../libs/firebase';
import { Link } from 'react-router-dom';
import Open from '../../media/open.webp'
import emailjs from '@emailjs/browser';
import ToDo from '../ToDo/ToDo';

function OnGoing() {

    //abrindo acordeon
    const [open, setopen]=useState(false)
    const handleOpen = (id)=>{
      setopen(id)
    }
  
    const hancleClose = ()=>{
      setopen(!open)
    }
  

    //states para pegar infos e fazer filtro
    const [projects, setProjects]=useState([]);
    const [loading, setLoading]=useState(false);
    const [search, setSearch]=useState('');
    const [serachFilter, setSerachFilter]=useState('Busca Múltipla');
    const [data1, setData1]=useState('')
    const [data2, setData2]=useState('')
 
    const [clients, setClients]=useState([]);
    const [projects2, setProjects2]=useState([]);

  //pegando infos projeto
  useEffect(()=>{
        
    const q = query(projectsCollectionRef,where('projStatus1', '==', 'Em Andamento'), orderBy("projNumber", "asc") );
    const r = query(clientsCollectionRef);
    const s = query(projectsCollectionRef);
    setLoading(true);
    const change = onSnapshot(q, snapshot=>{
      setProjects(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      
    })
     const change2 = onSnapshot(r, snapshot=>{
              setClients(snapshot.docs.map(doc=>({
              data:doc.data(),
              id:doc.id
              })))
                setLoading(false);
          })
    const change3 = onSnapshot(s, snapshot=>{
      setProjects2(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
      
    })

     return()=>{change();change2();change3() }

},[]);

//limpando serch
  const handleClear=()=>{
    setSearch('')
  }
  const handleClear2=()=>{
    setData1('')
    setData2('')
  }

  //mudando estatus do projeto
  const handleChangeStatus = async(id, status, name, client, type, info, valor, moeda)=>{
    if(status === 'Em Pausa'){
      

      const analystDoc = doc(db, 'projects', id)
      const newField = ({projStatus1: status})
      await updateDoc(analystDoc , newField)
    }
    if(status === 'Em Análise'){
      const analystDoc = doc(db, 'projects', id)
      const newField = ({projStatus1: status})
      await updateDoc(analystDoc , newField)
    }
    if(status === 'Entregue'){
      const res = new Date();
      const pagamentoData = new Date(res.setDate(res.getDate() + 30)).toISOString().slice(0,10);
      const analystDoc = doc(db, 'projects', id)
      const newField = ({projStatus1: status, projeStatus2: 'A Cobrar', projeStatus3: 'A Pagar', projDeliveryDate: new Date().toISOString().slice(0,10), projPaymentDate:pagamentoData, projPaymentAlert:false})
      await updateDoc(analystDoc , newField)
      var templateParams = {
        subject: `Projeto entregue ${name}`,
        name: 'Aparecida, ',
        message: `O projeto ${name} foi entrege e já podemos proceder com o a cobrança e o pagamento dos analistas do projeto para o dia: ${new Date(pagamentoData).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)} `,
        cliente: `Nome do cliente ${client}`,
        tipo:`Tipo de cobrança ${type? type: 'Informação não registrada'}`,
        informa:`Informção para cobrança:
         ${info? info: 'Informações não registradas'}`,
        valores:`Valor da Cobrança: ${moeda? moeda: ''}: ${valor? Math.round(valor).toLocaleString('pt-br'): 'Valor Não Definido'}`,
        to:['financeiro@nexointell.com', 'antoniobelias@gmail.com', 'luisesnal@gmail.com']
      };
      emailjs.send('GmailNexoIntell', 'template_jpw18ri', templateParams, '4M21HcjlE7N2OeNsv')
          .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
          }, function(error) {
            console.log('FAILED...', error);
          });
    } 
  }
  const sendEmailTeam =async (e, id, projName, lideres, analistas)=>{
    e.preventDefault();
    var lideresNome = lideres? lideres.map((item) => item.name.toString().split(',')):'';
    var analistasNome = analistas? analistas.map((item) => item.name.toString().split(',')):'';
    var analistasEmail = analistas? analistas.map((item) => item.email.toString().split(',')):'';
    var lideresEmail = lideres? lideres.map((item) => item.email.toString().split(',')):'';
    var templateParams = {
      subject:` 5 days to Dead Line - Alert Project ${projName} - 5 Dias Para Entrega do Projeto ${projName}`,
      name: [lideresNome, analistasNome],
      message: `O projeto ${projName} está proximo de sua conclusão, por favor nos dê retorno de como está o andamento`,
      message1:`The project ${projName} is close to its delivery date, please give us feedback on progress`,
      to: [analistasEmail, lideresEmail, 'antoniobelias@gmail.com', 'luisesnal@gmail.com']
    };
    emailjs.send('GmailNexoIntell', 'template_jpw18ri', templateParams, '4M21HcjlE7N2OeNsv')
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
          console.log('FAILED...', error);
        });
        const analystDoc = doc(db, 'projects', id)
        const newField = ({projDeadLineAlert: true })
        await updateDoc(analystDoc , newField) 
  }
  const sendEmailTeam2 =async (e, id, projName, lideres, analistas)=>{
    e.preventDefault();
    var lideresNome = lideres? lideres.map((item) => item.name.toString().split(', ')):'';
    var analistasNome = analistas? analistas.map((item) => item.name.toString().split(', ')):'';
    var analistasEmail = analistas? analistas.map((item) => item.email.toString().split(',')):'';
    var lideresEmail = lideres? lideres.map((item) => item.email.toString().split(',')):'';
    var templateParams = {
      subject:` 2 Days to Dead Line - Alert Project ${projName} - 2 Dias Para Entrega do Projeto ${projName}`,
      name: [lideresNome, analistasNome],
      message: `O projeto ${projName} está proximo de sua conclusão, por favor nos dê retorno de como está o andamento`,
      message1:`The project ${projName} is close to its delivery date, please give us feedback on progress`,
      to: [analistasEmail, lideresEmail, 'antoniobelias@gmail.com', 'luisesnal@gmail.com']
    };
    emailjs.send('GmailNexoIntell', 'template_jpw18ri', templateParams, '4M21HcjlE7N2OeNsv')
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
          console.log('FAILED...', error);
        });
        const analystDoc = doc(db, 'projects', id)
        const newField = ({projDeadLineAlert2: true })
        await updateDoc(analystDoc , newField) 
  }
      
  const handeprojIntStatus1= async(e, id)=>{
    e.preventDefault()
    const analystDoc = doc(db, 'projects', id)
    const newField = ({projIntStatus:true})
    await updateDoc(analystDoc , newField)
  }
  const handeprojIntStatus2= async(e, id)=>{
    e.preventDefault()
    const analystDoc = doc(db, 'projects', id)
    const newField = ({projIntStatus:false})
    await updateDoc(analystDoc , newField)
  }
  const numerodeprojetos =(nome)=>{
    var n1= clients.filter((val)=>{if(val.data.clientName === nome ){return val}}).map((i)=>i.data.clientProjects)
  var n2=n1? parseInt(n1):0
  var n3= parseInt(projects2.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === nome){return val}}).length)
  var total = n2+n3
  return(total)
}
  return (
  <div className='superContainer'>
        
        <SideBarProjects/>
        
        <div className='pageHeader'>
            <ToDo />
              <div className="pageTitleFull">
                  <div className="pageTitle">PROJETOS: EM ANDAMENTO</div>
                  
              </div>
              <div className='pageListSearch' style={{width:'100%'}}>

                <div className='pageListSearchAelect'>
                    FIltro: 
                    <select  onChange={(e)=>{setSerachFilter(e.target.value)}} >
                    <option value="Busca Múltipla">Busca Múltipla</option>
                      <option value="Nome">Nome</option>
                      <option value="Numero">Numero</option>
                      <option value="Ano">Ano</option>
                      <option value="Data Início">Data Início</option>
                      <option value="Data Final">Data Final</option>
                      <option value="Cliente">Cliente</option>
                      <option value="País de Contratação">País de Contratação</option>
                      <option value="Jurisdições">Jurisdições</option>
                      <option value="Lider de Projeto">Lider de Projeto</option>
                      <option value="Analistas">Analistas</option>
                      <option value="Comentários">Comentários</option>
                    </select>
                </div>
                {serachFilter !== 'Data Início' && serachFilter !== 'Data Final'? 
                <>
                <div className='pageListSearcInput' ><input type="text"  placeholder={`Buca por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
                <div className='pageListSearcClear' onClick={handleClear}><button>X</button></div>
                
                {serachFilter === 'Busca Múltipla'? 
                <div className='pageListSearcInput2' >Busca em Comentátios, País de Contratação, Jurisdições, Analistas e Lideres de Projeto</div>
                :''
                }
                </>
                :
                <>
                <div>DE: <input type="date" value={data1}  onChange={(e)=>{setData1(e.target.value)}} /></div><div>ATÉ: <input type="date" value={data2}  onChange={(e)=>{setData2(e.target.value)}} /></div>
                <div className='pageListSearcClear' onClick={handleClear2}><button>X</button></div>
                </>
              }
              </div>
      </div>
            <div className='pagesContent'>
              {loading &&
              <div className="loading">
                  <div className='pageListProjects'>Loading....</div>
              </div>
              
              }
               {projects.filter((val)=>{
                         if(serachFilter === 'Busca Múltipla'){
                          if(search === ''){
                            return val 
                        } else if (val.data.projComments.toLowerCase().includes(search.toLowerCase()) ||val.data.projHiringCountry.toLowerCase().includes(search.toLowerCase()) || [val.data.projJurisdictions].toString().toLowerCase().includes(search.toLowerCase()) || val.data.projAnalistas.map((item)=>item.name).toString().toLowerCase().includes(search.toLowerCase()) || val.data.projLideres.map((item)=>item.name).toString().toLowerCase().includes(search.toLowerCase()) ){
                            return val
                        }
                      }
                      if(serachFilter === 'Data Final'){
                        if(data1 === '' || data2 ===''){
                          return val 
                      } else if (val.data.projDeadLine>= data1 && val.data.projDeadLine <= data2){
                          return val
                      }
                    }
                         if(serachFilter === 'Data Início'){
                          if(data1 === '' || data2 === ''){
                            return val 
                        } else if (val.data.projStartDate >= data1 && val.data.projStartDate <= data2){
                            return val
                        }
                      }
                       if(serachFilter === 'Ano'){
                        if(search === ''){
                          return val 
                      } else if (new Date(val.data.projStartDate).getFullYear().toString().includes(search.toString())){
                          return val
                      }
                    }
                       if(serachFilter === 'Nome'){
                            if(search === ''){
                              return val
                          } else if (val.data.projName.toLowerCase().includes(search.toLowerCase())){
                              return val
                          }
                        }
                        if(serachFilter === 'Numero'){
                          if(search === ''){
                            return val
                        } else if (val.data.projNumber.toString().includes(search)){
                            return val
                        }
                        }
                        if(serachFilter === 'Cliente'){
                          if(search === ''){
                            return val
                        } else if (val.data.projClient.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'País de Contratação'){
                              if(search === ''){
                                return val
                            } else if (val.data.projHiringCountry.toLowerCase().includes(search.toLowerCase())){
                                return val
                            }
                        }
                        if(serachFilter === 'Jurisdições'){
                          if(search === ''){
                            return val
                        } else if ([val.data.projJurisdictions].toString().toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'Lider de Projeto'){
                          if(search === ''){
                            return val
                        } else if (val.data.projLideres? val.data.projLideres.map((item)=>item.name).toString().toLowerCase().includes(search.toLowerCase()):''){
                            return val
                        }
                        }
                        if(serachFilter === 'Analistas'){
                          if(search === ''){
                            return val
                        } else if (val.data.projAnalistas? val.data.projAnalistas.map((item)=>item.name).toString().toLowerCase().includes(search.toLowerCase()):''){
                            return val
                        }
                        }
                        if(serachFilter === 'Comentários'){
                          if(search === ''){
                            return val
                        } else if (val.data.projComments.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        
                    }).map((item, index)=>(
                    <div className='pageListProjects' key={index} onLoad={item.data.projDeadLineAlert2===false && (new Date(item.data.projDeadLine) - new Date())/ (1000 * 60 * 60 * 24) <= 2? (e)=>{sendEmailTeam2(e,item.id,item.data.projName, item.data.projLideres? item.data.projLideres:'' , item.data.projAnalistas? item.data.projAnalistas:'')}
                    :
                    item.data.projDeadLineAlert===false && (new Date(item.data.projDeadLine) - new Date())/ (1000 * 60 * 60 * 24) <= 5? (e)=>{sendEmailTeam(e,item.id,item.data.projName, item.data.projLideres? item.data.projLideres:'' , item.data.projAnalistas? item.data.projAnalistas:'')}
                    :
                    (e)=>{e.preventDefault()}  }
                      style={
                        item.data.projLideres.length===0  ||  item.data.projAnalistas.length===0 || item.data.projClientID==='' || item.data.projFinalValue==='' ? {border: '8px solid #710639'}:{}
                        &&
                        (new Date(item.data.projDeadLine) - new Date())/ (1000 * 60 * 60 * 24) <= 0? 
                        {backgroundColor:'#710606'}: {}
                        &&
                        (new Date(item.data.projDeadLine) - new Date())/ (1000 * 60 * 60 * 24) <= 2? 
                        {backgroundColor:'#710639'}: {}
                        &&
                        (new Date(item.data.projIntermediateDate) - new Date())/ (1000 * 60 * 60 * 24) <= 2 && item.data.projIntStatus ===false ?
                        {backgroundColor:'#B64F05'}: {}
                      }
                    >
                        <div className='pageListCLientsA'>
                            <div className="clientFit">
                              <span>Ano:</span>
                              <p>{new Date(item.data.projStartDate).getFullYear()}</p>
                            </div>
                            <div className="clientFit">
                              <span>Numero:</span>
                              <p style={{textAlign:'center', width:'100%'}}>{item.data.projNumber}</p>
                            </div>
                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                              <span>Nome:</span>
                              <p style={{whiteSpace:'nowrap'}}>{item.data.projName}</p>
                            </div>
                            <div className="clientFlex1" style={{overflow:'hidden'}}>
                              <span>Cliente:</span>
                              <p style={{whiteSpace:'nowrap'}}><Link to={'/cliente/'+item.data.projClientID}>{item.data.projClient}</Link></p>
                            </div>
                            <div className="clientFit" style={{overflow:'hidden'}}>
                              <span>Projetos:</span>
                             <p>{numerodeprojetos(item.data.projClient)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Data Início:</span>
                              <p>{new Date(item.data.projStartDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                            </div>
                            <div className="clientFit">
                              <span>Data Intermediária:</span>
                              <p>{item.data.projIntermediateDate? new Date(item.data.projIntermediateDate).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10): 'Sem Intemediária'}</p>
                            </div>
                            <div className="clientFit">
                              <span>Intermediária:</span>
                              <p>{item.data.projIntermediateStatus==='Sem'? 'Sem Intemediária': item.data.projIntermediateStatus}</p>
                            </div>
                            {item.data.projIntermediateStatus==='Sem'? '':
                            <div className="clientFit">
                              <span>I. Entregue:</span>
                              {item.data.projIntStatus? <button className='seeClient2' onClick={(e)=>{handeprojIntStatus2(e, item.id)}}>SIM</button>
                              :
                              <button className='seeClient2' onClick={(e)=>{handeprojIntStatus1(e, item.id)}}>NÃO</button>}
                            </div>
                              }
                            <div className="clientFit">
                              <span>Data Final:</span>
                              <p>{new Date(item.data.projDeadLine).toLocaleString('pt-BR',{timeZone: 'UTC'}).slice(0, 10)}</p>
                            </div>
                            <div style={{display:'none'}}>{item.data.projClientBill}{item.data.projClientBillInfo}</div>
                            <div className="clientFlex1">
                              <span>Status do projeto:</span>
                              <select onChange={(e)=>{handleChangeStatus(item.id, e.target.value, item.data.projName, item.data.projClient,  item.data.projClientBillInfo, item.data.projClientBill, item.data.projFinalValue, item.data.projCurrency)}}>
                                <option value="">{item.data.projStatus1}</option>
                                <option value="Em Análise">Em Análise</option>
                                <option value="Em Pausa">Em Pausa</option>
                                <option value="Entregue">Entregue</option>
                              </select>
                              
                            </div>
                            <div className='openClientContent'>
                              {open === item.id? 
                              <button className='closeClient' onClick={hancleClose}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                              :
                              <button className='openClient' onClick={()=>(handleOpen(item.id))}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                              }
                            </div>
                      </div>
                      <div className={open === item.id? 'pageListCLientsB listActive' : 'pageListCLientsB' }>
                        <div className='pageListCLientsBLinhas'>
                            <div className="clientFlex1">
                                <span>País de contratação:</span>
                                <p>{item.data.projHiringCountry}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Juridições:</span>
                                <p>{item.data.projJurisdictions.join('  -  ')}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Valor Final Total:</span>
                                <p>{item.data.projCurrency}: ${item.data.projFinalValue? Math.round(item.data.projFinalValue).toLocaleString('pt-br'): 'A registrar'}</p>
                            </div>
                            
                            <div className="clientFlex1">
                                <span>Entrevistas:</span>
                                <p>{item.data.projSources? item.data.projSources: 'Não Registrado'}</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Tipo de Projeto:</span>
                                <p>{item.data.projType=== ''?  'Não Registrado' : item.data.projType=== 'Reputacional'? 'Humint' : item.data.projType=== 'Ivestigação'? 'Documentos' : item.data.projType }</p>
                            </div>
                            <div className="clientFlex1">
                                <span>Tipo de Cliente:</span>
                                <p>{item.data.projClientType? item.data.projClientType: 'Não Registrado'}</p>
                            </div>
                           
                        </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className='clientFlex1'>
                              <div className='clientFlexTitle'>Comentários</div>
                              <div className='clientFlexBox' style={{height:'100%'}}>
                                <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.projComments}</pre>
                              </div>
                            </div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                              <div className='clientFlexTitle'>TIme do projeto</div>
                        </div>
                        <div className='pageListCLientsBLinhas'>
                            <div className='clientFlex1'>
                                <div className='clientFlexTitle'>lider de projeto</div>
                                <div className='analistasFlex1'>
                                  {item.data.projLideres && item.data.projLideres.length>0? item.data.projLideres.map((item, index)=>(
                                  <div className='pageListCLientsBLinhas'  key={index}>
                                    <div className="clientFlex1">
                                      <span>Líder de projeto:</span>
                                      <p><Link to={'/colaborador/'+item.link}>{item.name}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>País:</span>
                                      <p>{item.country}</p>
                                    </div>
                                    <div className="clientFlex1" >
                                      <span>Email:</span>
                                      <p  style={{textTransform:'lowercase'}}>{item.email}</p>
                                    </div>
                                   </div>
                                  )) :
                                  <div className='pageListCLientsBLinhas'>
                                    <div className="clientFlex1">
                                      <span>Líder de projeto:</span>
                                      <p>Não Registrado</p>
                                    </div>
                                    </div>}
                                </div>
                            </div>
                            <div className='clientFlex1'>
                                <div className='clientFlexTitle'>Analistas</div>
                                <div className='analistasFlex1'>
                                  {item.data.projAnalistas && item.data.projAnalistas.length>0? item.data.projAnalistas.map((item, index)=>(
                                    <div className='pageListCLientsBLinhas'key={index}>
                                    <div className="clientFlex1" >
                                      <span>{item.category}</span>
                                      <p><Link to={'/colaborador/'+item.link}>{item.name}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>País:</span>
                                      <p>{item.country}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Email:</span>
                                      <p style={{textTransform:'lowercase'}}>{item.email}</p>
                                    </div>
                                    </div>
                                    
                                  )) :
                                  <div className='pageListCLientsBLinhas'>
                                  <div className="clientFlex1">
                                    <span>Analista:</span>
                                    <p>Não Registrado</p>
                                  </div>
                                  </div>}
                                </div>
                            </div>
                                 <div className='clientFlex1'>
                                <div className='clientFlexTitle'>Fornecedores</div>
                                <div className='analistasFlex1'>
                                  {item.data.projFornecedors && item.data.projFornecedors.length>0? item.data.projFornecedors.map((item, index)=>(
                                    <div className='pageListCLientsBLinhas'key={index}>
                                    <div className="clientFlex1" >
                                      <span>{item.category}</span>
                                      <p><Link to={'/colaborador/'+item.link}>{item.name}</Link></p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>País:</span>
                                      <p>{item.country}</p>
                                    </div>
                                    <div className="clientFlex1">
                                      <span>Email:</span>
                                      <p style={{textTransform:'lowercase'}}>{item.email}</p>
                                    </div>
                                    </div>
                                    
                                  )) :
                                  <div className='pageListCLientsBLinhas'>
                                  <div className="clientFlex1">
                                    <span>Analista:</span>
                                    <p>Não Registrado</p>
                                  </div>
                                  </div>}
                                </div>
                            </div>
                        </div>
                        {item.data.projDeadLineAlert? 
                        <div className='pageListCLientsBLinhas'>
                           <div className='clientFlexTitle'>ALERTA DE PROJETO DE 5 DIAS ENVIADO!</div>
                        </div>
                        
                        : ''}
                         {item.data.projDeadLineAlert2? 
                        <div className='pageListCLientsBLinhas'>
                           <div className='clientFlexTitle'>ALERTA DE PROJETO DE 2 DIAS ENVIADO!</div>
                        </div>
                        
                        : ''}
                        <Link to={"/projeto/"+item.id} className='seeClient'>VER Projeto</Link>
                       
                      </div>
                        
                    </div>
              ))}
       </div>
  </div>
  )
}

export default OnGoing