import React,{useState, useEffect} from 'react'
import SideBarClients from '../../Components/SideBar/SideBarClients'
import {clientsCollectionRef, projectsCollectionRef} from '../../services/fireref';
import {  onSnapshot, orderBy, query, deleteDoc, doc, where} from "firebase/firestore"
import { db } from '../../libs/firebase';
import { Link } from 'react-router-dom';
import Open from '../../media/open.webp'

function ClientActive() {
  //abrindo acordeon
  const [open, setopen]=useState(false)

  //states para pegar infos e fazer filtro
  const [clients, setClients]=useState([]);
  const [loading, setLoading]=useState(false);
  const [search, setSearch]=useState('');
  const [serachFilter, setSerachFilter]=useState('Nome');

  //projetos
  const [projects, setProjects]=useState([]);

  //abrindo acoredeon
  
  const handleOpen = (id)=>{
    setopen(id)
  }

  const hancleClose = ()=>{
    setopen(!open)
  }



  //pegando infos cliente
  useEffect(()=>{
        
      const q = query(clientsCollectionRef, where ('clientStatus', '==', 'Ativo'), orderBy('clientName', 'asc'));
       const r = query(projectsCollectionRef);
      setLoading(true);
      const change = onSnapshot(q, snapshot=>{
          setClients(snapshot.docs.map(doc=>({
          data:doc.data(),
          id:doc.id
          })))
      })
     
    const change2 = onSnapshot(r, snapshot=>{
      setProjects(snapshot.docs.map(doc=>({
        data:doc.data(),
        id:doc.id
        })))
        setLoading(false);
    })
          
      return()=>{change();change2() }

  },[]);

 

  //limpando serch
    const handleClear=()=>{
      setSearch('')
    }

      // deletandoo doC
      const [openDelete, setOpenDelete]=useState(false)
      const [deleting, setDeleting]=useState(false)
      const handleOpenDelete=()=>{
          setOpenDelete(true)
      }
    
    const handleCloseDelete =()=>{
        setOpenDelete(false)
    }
    
    const handleDeletClient =async(e, id)=>{
        e.preventDefault()
        setDeleting(true)
        await deleteDoc(doc(db, 'clients', id));
        setDeleting(false)
        
    }


  return (

    <div className='superContainer'>
        <SideBarClients/>
        <div className='pageHeader'>
              <div className="pageTitleLeft">
                  <div className="pageTitle">Clientes Ativos - Quantidade: {clients.length}</div>
              </div>
              <div className='pageListSearch'>

                <div className='pageListSearchAelect'>
                    Filtro: 
                    <select  onChange={(e)=>{setSerachFilter(e.target.value)}} >
                      <option value="Nome">Nome</option>
                      <option value="País">País</option>
                      <option value="Status">Status</option>
                      <option value="Desde">Desde</option>
                      <option value="Nome de Contatos">Nome de Contatos</option>
                      <option value="Comentários">Comentários</option>
                      <option value="Tipo de Faturamento">Tipo de Faturamento</option>
                      <option value="Informações de Faturamento">Informações de Faturamento</option>
                    </select>
                </div>
                <div className='pageListSearcInput' ><input type="text"  placeholder={`Buca por ${serachFilter}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} /></div>
                <div className='pageListSearcClear' onClick={handleClear}><button>X</button></div>
              </div>
      </div>
            <div className='pagesContent'>
            {loading &&
            <div className="loading">
                <div className='pageListCLients'>Loading....</div>
            </div>
            
            }
            {clients.filter((val)=>{
                       if(serachFilter === 'Nome'){
                            if(search === ''){
                              return val
                          } else if (val.data.clientName.toLowerCase().includes(search.toLowerCase())){
                              return val
                          }
                        }
                        if(serachFilter === 'País'){
                              if(search === ''){
                                return val
                            } else if (val.data.clientCountry.toLowerCase().includes(search.toLowerCase())){
                                return val
                            }
                        }
                        if(serachFilter === 'Desde'){
                          if(search === ''){
                            return val
                        } else if (val.data.clientStartYear.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'Nome de Contatos'){
                          if(search === ''){
                            return val
                        } else if (val.data.clientContacts.map((item)=>item.name).toString().toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'Comentários'){
                          if(search === ''){
                            return val
                        } else if (val.data.clientComments.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'Tipo de Faturamento'){
                          if(search === ''){
                            return val
                        } else if (val.data.clientBillingType.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'Informações de Faturamento'){
                          if(search === ''){
                            return val
                        } else if (val.data.clientBillingInfo.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        if(serachFilter === 'Status'){
                          if(search === ''){
                            return val
                        } else if (val.data.clientStatus.toLowerCase().includes(search.toLowerCase())){
                            return val
                        }
                        }
                        
                    }).map((item, index)=>(
            <div className='pageListCLients' key={index} >
                <div className='pageListCLientsA'>
                    <div className="clientFlex2">
                      <span>Nome:</span>
                      <p><a target='_blank' href={`https://analises.nexointell.com/cliente/${item.data.clientName}` }>{item.data.clientName}</a></p>
                    </div>
                    <div className="clientFlex1">
                      <span>Projetos:</span>
                      <p>{(item.data.clientProjects? parseInt(item.data.clientProjects):0) + parseInt(projects.filter((val)=>{if(val.data.projStatus1 !== 'deletado' && val.data.projClient === item.data.clientName){return val}}).length) }</p>
                    </div>
                    <div className="clientFlex1">
                      <span>País:</span>
                      <p>{item.data.clientCountry}</p>
                    </div>
                    <div className="clientFlex1">
                        <span>Status:</span>
                        <p>{item.data.clientStatus}</p>
                        </div>
                    <div className="clientFlex1">
                      <span>Desde:</span>
                      <p>{item.data.clientStartYear}</p>
                    </div>
                    <div className="clientFlex1">
                      <span>Web Site:</span>
                      <p style={{maxWidth: 18+'ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                      {item.data.clientWeb? <a href={item.data.clientWeb} style={{textTransform:'lowercase'}} target="_blank" rel="noopener noreferrer">{item.data.clientWeb}</a> :'Não Registrado'}
                        </p>
                    </div>
                    <div className='openClientContent'>
                      {open === item.id? 
                      <button className='closeClient' onClick={hancleClose}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                      :
                      <button className='openClient' onClick={()=>(handleOpen(item.id))}><img  src={Open} alt="open" width={'100%'} height={'auto'} /></button>
                      }
                    </div>
                </div>
                <div className={open === item.id? 'pageListCLientsB listActive' : 'pageListCLientsB' }>
                    {item.data.clientContacts.map((item,index)=>(
                      <div className='pageListCLientsBLinhas' key={index} id={item.id}>
                          <div className="clientFlex2">
                            <span>Nome Contato:</span>
                            <p>{item.name}</p>
                          </div>
                          <div className="clientFlex1">
                            <span>Telefone Contato:</span>
                            <p>{item.phone}</p>
                          </div>
                          <div className="clientFlex1">
                            <span>Email Contato:</span>
                            <p style={{textTransform:'lowercase'}}>{item.email}</p>
                          </div>
                      </div>
                  ))}
                  {item.data.clientDocs &&
                    item.data.clientDocs.map((item,index)=>(
                      <div className='pageListCLientsBLinhas' key={index} id={item.id}>
                          <div className="clientFlex1">
                            <span>Nome do documento:</span>
                            <p><a href={item.link} target="_blank" rel="noopener noreferrer">{item.name}</a></p>
                          </div>
                      </div>
                  ))}
                  
                  <div className='pageListCLientsBLinhas'>
                    <div className='clientFlex1'>
                          <div className='clientFlexTitle'>Comentários</div>
                          <div className='clientFlexBox' style={{height:'100%'}}>
                            <pre className='clientFlexBoxPre' style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.clientComments}</pre>
                          </div>
                    </div>
                    <div className='clientFlex1'>
                          <div className='clientFlexTitle'>Detalhes de Faturamento</div>
                          <div className='clientFlexP'>Faturamento: {item.data.clientBillingType}</div>
                          <div className='clientFlexBox'>
                            <pre className='clientFlexBoxPre'  style={{fontFamily:'"Ubuntu", sans-serif'}}>{item.data.clientBillingInfo}</pre>
                          </div>
                    </div>
                  </div>
                 
                  
                  <Link to={"/cliente/"+item.id} className='seeClient'>VER CLIENTE</Link>
                  <button className='editValues' onClick={handleOpenDelete}>APAGAR CLIENTE</button>
                          {openDelete &&
                          <div className="popUpDelete">
                          <div className="deleteAviso">
                              <div>Você realmente quer apagar esse cliente?</div>
                              <div className='deleteButtons'>
                                  <button onClick={handleCloseDelete} className='deleteNao'>❌ Não</button>
                                  <button onClick={(e)=>{handleDeletClient(e, item.id)}} className='deleteSim'>✔️ Sim</button>
                              </div>
                              {deleting &&
                              <div>Apagando Cliente</div>
                              }
                            </div>
                          
                        </div>
                          }                 
                </div>
                
              
            </div>
            ))}
        </div>
    </div>
    

  )
}

export default ClientActive